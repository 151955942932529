<template>
  <el-carousel :height="heightValue" :class="elCarouselClass">
    <el-carousel-item v-for="(item,index) in list" :key="index">
      <a :href="item['link']" :target="target">
        <el-image :class="elImageClass" fit="cover" :alt="item['description']"
                  :src="absolute ? item['coverPicture'] : baseImgURL+item['coverPicture']"></el-image>
      </a>
    </el-carousel-item>
  </el-carousel>
</template>

<script>
import {sendPostByKeyValue} from "@/utils/httpUtils";

export default {
  //adRotation
  name: "SlideshowImage",
  props: {

    /**
     * 模板类
     */
    elCarouselClass: {
      type: String, // 类型
      default: 'carousel' // 默认值
    },
    /**
     * 显示url在alt上
     */
    elImageClass: {
      type: String, // 类型
      default: 'w_100 h_100' // 默认值
    },
    /**
     * 绝对地址
     */
    absolute: {
      type: Boolean, // 类型
      default: false // 默认值
    },
    /**
     * 图片高度
     */
    heightValue: {
      type: String, // 类型
      default: '360px' // 默认值
    },
    /**
     * 打开链接方式
     */
    target: {
      type: String, // 类型
      default: '_blank' // 默认值
    },
    /**
     * 轮播图类型
     * 首页-0 事故车-1 水淹车-2 二手车-3 配件-4 求购-5
     *
     */
    type: {
      type: Number, // 类型
      default: 0, // 默认值
      validator: function (value) {
        // 定义有效的数值  首页-0 事故车-1 水淹车-2 二手车-3 配件-4 求购-5
        const isValid = [0, 1, 2, 3, 4, 5].includes(value);
        if (!isValid) {
          // 控制台警告
          console.warn(`轮播图类型参数不存在：${value}`);
        }
        return isValid;
      }
    },
  },

  data() {
    return {
      //轮播图数据
      list: [],
      //轮播图类型
      typeData: {
        //首页
        homePage: 0,
        //事故车
        carInAccident: 1,
        //水淹车
        waterBubbleCar: 2,
        //二手车
        usedCar: 3,
        //配件
        accessories: 4,
        //求购
        toBuy: 5,
      },
      baseImgURL: undefined,
    }
  },
  created() {
    this.init()
  },
  methods: {
    /**
     * 初始化数据
     */
    init() {
      this.getList(this.type)
      this.baseImgURL = process.env.VUE_APP_BASE_IMG;
    },

    /**
     * 获取轮播图
     */
    getList(type) {
      // 查询事故车轮播图
      sendPostByKeyValue("/slideshow/list", {type: type})
          .then((response) => {
            if (response['code'] === 200) {
              this.list = response['data']
            }
          })
          .catch((err) => {
            console.log('获取轮播图失败：', err);
          });
    },
  }

}
</script>

<style scoped>

</style>
