import {sendGet} from '@/utils/httpUtils'

/**
 * 获取联系方式
 * @param self js使用vue方法
 * @param type 客服电话类型 0-其他客服电话 1-用户客服电话 2-车辆客服电话 3-配件客服电话 4-托运客服电话 5-求购客服电话
 */
export function show(self, type) {
    sendGet(`/serviceTel/getTelByType?type=${type}`)
        .then((response) => {
            if (response["code"] === 200) {
                let tel = response.data;
                self.$alert(tel, '联系方式', {
                    confirmButtonText: '确定',
                    type: 'warning',
                    center: true
                }).then(() => {
                    // 点击确定
                }).catch(() => {
                    // 点击取消
                });
            } else {
                self.$message.error(response["message"] ? response["message"] : "电话查询失败");
            }
        })
        .catch((err) => {
            console.log(err);
        });
}


export default {
    show
}
