<template>
  <el-row type="flex">
    <div class="detail_img">
      <el-image v-if="list.length && showImage" :src="showImage" fit="cover" class="w_100 h_100"
      ></el-image>
    </div>
    <div class="detail_img_thumbs">
      <swiper v-if="list.length" :options="swiperOptions" class="w_100 h_100">
        <swiper-slide v-for="(item,index) in list" :key="index">
          <el-image :src="item" fit="cover" class="w_100 h_100" @click="showImages(item)"></el-image>
        </swiper-slide>
      </swiper>
    </div>
    <!--    <div class="detail_img_thumbs">
          <swiper v-if="list.length" :options="swiperOptions" class="w_100 h_100 swiper-container">
            <swiper-slide v-for="(item,index) in list" :key="index" class="swiper-slide">
              <el-image  :src="item" fit="cover" class="w_100 h_auto" @click="showImages(item)"></el-image>
            </swiper-slide>

            &lt;!&ndash; 添加一个空的 swiper-slide 来确保滚动条显示正确 &ndash;&gt;
            <div class="swiper-slide" v-if="list.length > 0" style="height: 1px;"></div>
          </swiper>
        </div>-->
  </el-row>
</template>

<script>
import {Swiper, SwiperSlide} from 'vue-awesome-swiper'

export default {
  name: "DetailSwiper",
  props: {
    /**
     * 值
     */
    value: [String, Object, Array],
    /**
     * 绝对地址
     */
    absolute: {
      type: Boolean, // 类型
      default: false // 默认值
    },
  },
  components: {
    Swiper, SwiperSlide
  },
  data() {
    return {
      //参数设置
      swiperOptions: {
        spaceBetween: 16,
        slidesPerView: 4,
        direction: 'vertical',
      },
      baseImgURL: undefined,
      list: [],
      showImage: undefined,
    }
  },
  created() {
    this.init()
  },
  watch: {
    /**
     * 监听传入值
     * @param newValue 新值
     * @param oldValue 旧值
     */
    value(newValue, oldValue) {
      this.init()
    }
  },
  methods: {
    photoFormatter(pictures) {
      let data=[]
      if (pictures != null && pictures != "") {
        data.push(pictures)
      }
      return data;
    },
    init() {
      this.baseImgURL = process.env.VUE_APP_BASE_IMG;
      this.check()
    },
    check() {
      const isValid = Array.isArray(this.value);
      if (!isValid) {
        // 控制台警告
        // console.warn(`swiper输入参数必须为['url']类型数组：`, this.value);
        return;
      }
      this.list = this.value
      if (this.list && this.list.length) {
        for (let index = 0; index < this.list.length; index++)
          this.list[index] = this.absolute ? this.list[index] : this.baseImgURL + this.list[index]
        this.showImage = this.list[0]
      }
    },
    showImages(url) {
      if (url)
        this.showImage = url
    }
  }
}
</script>

<style scoped>
.swiper-container {
  /* 设定 swiper 的高度，你可以根据需要调整 */
  height: 385px; /* 例如 */
  overflow-y: auto; /* 超出高度时显示垂直滚动条 */
}

.swiper-slide {
  /* 根据需要调整 swiper-slide 的样式 */
  //display: flex;
  //align-items: center;
  //justify-content: center;
  width: 85px;
  height: 85px;
}


.swiper-container::-webkit-scrollbar {
  display: none; /* 对于 WebKit 浏览器 */
}

.swiper-container {
  -ms-overflow-style: none; /* IE 和 Edge */
  scrollbar-width: none; /* Firefox */
}
</style>
