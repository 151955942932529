import axios from 'axios'
import {Message, MessageBox} from 'element-ui'
import store from '@/store'
import {getToken} from '@/utils/auth'

/**
 * 登陆状态已过期执行方法
 */
function loginStatusHasExpired() {
    // 打印登录前页面
    let href = location.pathname + location.search
    localStorage.setItem('PAGE_BEFORE_LOGIN', href);
    // console.log('PAGE_BEFORE_LOGIN', location.href)
    store.dispatch('user/resetToken').then(() => {
        window.location.href = '/login';
    })
}

// create an axios instance
axios.defaults.headers.post['Content-Type'] = 'application/json;charset=UTF-8';        //配置请求头
const service = axios.create({
    baseURL: process.env.VUE_APP_BASE_API, // url = base url + request url
    // withCredentials: true, // send cookies when cross-domain requests
    timeout: 20000 // request timeout
})

// request interceptor
service.interceptors.request.use(
    config => {
        // do something before request is sent

        /*if (store.getters.token) {
            config.headers['Token'] = getToken()
        }*/
        if(getToken())
        config.headers['Token'] = getToken()
        return config
    },
    error => {
        // do something with request error
        console.log(error) // for debug
        return Promise.reject(error)
    }
)


let userErrorEnum = {
    "1": '用户不存在！',
    "2": '用户状态异常！',
    "3": '用户已冻结！',
    "4": '用户已过期！',
}

// response interceptor
service.interceptors.response.use(
    /**
     * If you want to get http information such as headers or status
     * Please return  response => response
     */

    /**
     * Determine the request status by custom code
     * Here is just an example
     * You can also judge the status by HTTP Status Code
     */
    response => {
        const res = response.data

        // if the custom code is not 20000, it is judged as an error.
        if (res.code !== 200) {
            Message({
                message: res.message || 'Error',
                memberType: 'error',
                duration: 5 * 1000
            })

            // 50008: Illegal token; 50012: Other clients logged in; 50014: Token expired;
            if (res.code === 401) {
                // to re-login
                let errorTitle = userErrorEnum[res.message]
                MessageBox.confirm(errorTitle ? errorTitle : '请先登录账号', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    memberType: 'warning'
                }).then(() => {
                    loginStatusHasExpired()
                }).catch(() => {  
                    // 这里处理可能的错误或拒绝情况，如果不需要处理可以忽略  
                })
                // .finally(()=>{
                //     loginStatusHasExpired()
                // })
            }
            return Promise.reject(new Error(res.message || 'Error'))
        } else {
            return res
        }
    },
    error => {
        console.log('err:' + error) // for debug

        if (error.response.data.code === 401) {
            let errorTitle = userErrorEnum[error.response.data.message]
            // to re-login
            MessageBox.confirm(errorTitle ? errorTitle : '请先登录账号', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                memberType: 'warning'
            }).then(() => {
                loginStatusHasExpired()
            }).catch(() => {  
                // 这里处理可能的错误或拒绝情况，如果不需要处理可以忽略  
            })
            // .finally(()=>{
            //     loginStatusHasExpired()
            // })
        } else if (error.response.data.code === 400) {
            return error.response.data
        }
        return Promise.reject(error)
    }
)

export default service
