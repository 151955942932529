import VueRouter from 'vue-router'

const router = new VueRouter({
    mode: 'history', // 设置模式为历史模式
    scrollBehavior(to, from, savedPosition) {
        // 返回位置
        if (savedPosition) {
            return savedPosition;
        } else {
            return {x: 0, y: 0};
        }
    },
    routes: [
        {
            path: '/',
            name: 'index',
            component: () => import('@/views/index'),
            redirect: '/',
            children: [
                {
                    path: '',
                    name: '',
                    component: () => import('@/views/home')
                },
                {
                    path: 'accident',
                    name: 'accidentIndex',
                    component: () => import('@/views/vehicle/accident'),
                },
                {
                    path: 'accident/detail',
                    name: 'accidentDetail',
                    component: () => import('@/views/vehicle/accident-detail'),
                },
                {
                    path: 'waterlogged',
                    name: 'waterloggedIndex',
                    component: () => import('@/views/vehicle/waterlogged'),
                },
                {
                    path: 'used',
                    name: 'usedIndex',
                    component: () => import('@/views/vehicle/used'),
                },
                {
                    path: 'accessories',
                    name: 'accessoriesIndex',
                    component: () => import('@/views/accessories/accessories'),
                },
                {
                    path: 'accessories/detail',
                    name: 'accessoriesDetail',
                    component: () => import('@/views/accessories/accessories-detail'),
                },
                {
                    path: 'want',
                    name: 'wantIndex',
                    component: () => import('@/views/want/want'),
                },
                {
                    path: 'accessories/mountings',
                    name: 'queryMountings',
                    component: () => import('@/views/accessories/query_mountings'),
                },
                {
                    path: 'accessories/preferredMerchants',
                    name: 'mountingsOrder',
                    component: () => import('@/views/accessories/preferred-merchants.vue'),
                },

                {
                    path: 'transport',
                    name: 'transportIndex',
                    component: () => import('@/views/transport/transport'),
                },

            ]
        },
        {
            path: '/accessories/publish',
            name: 'publish',
            component: () => import('@/views/accessories/seller-publish.vue'),
        },
        {
            path: '/accessories/mountings_detail',
            name: 'mountingsDetail',
            component: () => import('@/views/accessories/mountings_detail.vue'),
        },

        {
            path: '/accessories/management',
            name: 'management',
            component: () => import('@/views/accessories/seller-management.vue'),
        },
        {
            path: '/login',
            name: 'login',
            component: () => import('@/views/login/login'),
            children: [
                {
                    path: 'verify',
                    name: 'verify',
                    component: () => import('@/views/login/signin-verify'),
                },
                {
                    path: 'password',
                    name: 'password',
                    component: () => import('@/views/login/signin-password'),
                },
                {
                    path: 'signup',
                    name: 'signup',
                    component: () => import('@/views/login/signup'),
                },
            ],
            redirect: '/login/verify',
        },
        {
            path: '/personal',
            name: 'personalCenter',
            component: () => import('@/views/personal/personal-center'),
            redirect: '/personal/profile',
            children: [
                {
                    title: '角色审核',
                    path: '/personal/notAudit',
                    name: 'notAudit',
                    component: () => import('@/views/personal/not_audit.vue'),
                },
                {
                    title: '我的任务',
                    path: 'task',
                    name: 'myTask',
                    meta: {roles: [1, 2,]},
                    component: () => import('@/views/personal/my-task'),
                },
                {
                    title: '我的任务-任务详情',
                    path: 'task/detail',
                    name: 'myTaskDetail',
                    meta: {roles: [1, 2,]},
                    component: () => import('@/views/personal/my-task-detail'),
                },
                {
                    title: '交易记录',
                    path: 'transaction',
                    name: 'myTransaction',
                    meta: {roles: [3, 4, 5, 6]},
                    component: () => import('@/views/personal/my-transaction'),
                },
                {
                    title: '我的资产',
                    path: 'property',
                    name: 'myProperty',
                    meta: {roles: [0, 1, 2, 3, 4, 5, 6]},
                    component: () => import('@/views/personal/my-property'),
                },
                {
                    title: '我的订单',
                    path: 'order',
                    name: 'myOrder',
                    meta: {roles: [0, 1, 2, 3, 4, 5, 6]},
                    component: () => import('@/views/personal/my-order'),
                },
                {
                    title: '我的求购',
                    path: 'want',
                    name: 'myWant',
                    meta: {roles: [0, 1, 2, 3, 4, 5, 6]},
                    component: () => import('@/views/personal/my-want'),
                },
                {
                    title: '我的收藏',
                    path: 'follow',
                    name: 'myFollow',
                    meta: {roles: [0, 1, 2, 3, 4, 5, 6]},
                    component: () => import('@/views/personal/my-follow'),
                },
                {
                    title: '地址管理',
                    path: 'address',
                    name: 'myAddress',
                    meta: {roles: [0, 1, 2, 3, 4, 5, 6]},
                    component: () => import('@/views/personal/my-address'),
                },
                {
                    title: '店铺信息',
                    path: 'store',
                    name: 'myStoreInfo',
                    meta: {roles: [3]},
                    component: () => import('@/views/personal/my-store-info'),
                },
                {
                    title: '个人信息',
                    path: 'profile',
                    name: 'myProfile',
                    meta: {roles: [0, 1, 2, 3, 4, 5, 6]},
                    component: () => import('@/views/personal/my-profile'),
                },
            ],
        },

        {
            path: '/login/role',
            name: 'selectRole',
            component: () => import('@/views/login/signup-role'),
        },
        {
            path: '/verification',
            name: 'forgotPassword',
            component: () => import('@/views/login/password-forgot'),
        },
        {
            path: '/setpassword',
            name: 'setPassword',
            component: () => import('@/views/login/password-set'),
        },
        {
            path: '/palceorder',
            name: 'palceOrder',
            component: () => import('@/views/vehicle/place-order'),
        },
        {
            path: '/mountingsOrder',
            name: 'mountingsOrder',
            component: () => import('@/views/accessories/mountings_order.vue'),
        },
        {
            path: '/accessories/confirmOrder',
            name: 'confirmOrder',
            component: () => import('@/views/accessories/confirm-order.vue'),
        },
        {
            path: '/palceorder/confirm',
            name: 'confirmOrder',
            component: () => import('@/views/vehicle/confirm-order'),
        },
        {
            path: '/vehicle/publish',
            name: 'publishVehicle',
            component: () => import('@/views/vehicle/seller-publish'),
        },
        {
            path: '/accident/vehicle_detail',
            name: 'vehicleDetail',
            component: () => import('@/views/vehicle/vehicle-detail.vue'),
        },
        {
            path: '/vehicle/management',
            name: 'managementVehicle',
            component: () => import('@/views/vehicle/seller-management'),
        },
        {
            path: '/personal/transaction/filter',
            name: 'transactionFilter',
            component: () => import('@/views/personal/transaction-filter'),
        },
        {
            path: '/vip',
            name: 'vipCenter',
            component: () => import('@/views/personal/vip'),
        },
        {
            path: '/transport/palceorder',
            name: 'palceTransportOrder',
            component: () => import('@/views/transport/place-order'),
        },

        {
            path: '/transport/confirmOrder',
            name: 'confirmOrder',
            component: () => import('@/views/transport/confirm-order.vue'),
        },
        {
            path: '/transport/detail',
            name: 'transportDetail',
            component: () => import('@/views/transport/transport-detail.vue'),
        },

        {
            path: '/auction-document',
            name: '文档',
            component: () => import('@/views/auction-document'),
        },
        // 其他路由规则...
        {path: '*', component: () => import('@/views/404')},// 404页面路由，确保这条规则在最后
    ]
})
export default router
