<template>
  <a v-if="getFirstImage()['coverPicture']" :href="getFirstImage()['link']" :target="target">
    <el-image :src="absolute ? getFirstImage()['coverPicture'] : baseImgURL+getFirstImage()['coverPicture']" fit="cover"
              :class="elImageClass" :alt="getFirstImage()['description']"></el-image>
  </a>
</template>

<script>
import {sendPostByKeyValue} from "@/utils/httpUtils";

export default {
  name: "AdvertisingImage",
  props: {
    /**
     * 显示url在alt上
     */
    elImageClass: {
      type: String, // 类型
      default: 'ad_img mt_4' // 默认值
    },
    /**
     * 绝对地址
     */
    absolute: {
      type: Boolean, // 类型
      default: false // 默认值
    },
    /**
     * 打开链接方式
     */
    target: {
      type: String, // 类型
      default: '_blank' // 默认值
    },
    /**
     * 下标 从0开始
     */
    index: {
      type: Number, // 类型
      default: 0, // 默认值
      validator: function (value) {
        // 验证是否为整数
        const isInteger = Number.isInteger(value);
        // 验证是否为非负数
        const isNonNegative = value >= 0;
        if (!(isInteger && isNonNegative))
            // 控制台警告
          console.warn(`广告下标不能为负数或小数：${value}`);


        // 如果value是整数且非负数，则返回true；否则返回false
        return isInteger && isNonNegative;
      }
    },
    /**
     * 广告类型
     * 首页-0 事故车-1 水淹车-2 二手车-3 配件-4 求购-5
     *
     */
    type: {
      type: Number, // 类型
      default: 0, // 默认值
      validator: function (value) {
        // 定义有效的数值  首页-0 事故车-1 水淹车-2 二手车-3 配件-4 求购-5
        const isValid = [0, 1, 2, 3, 4, 5].includes(value);
        if (!isValid) {
          // 控制台警告
          console.warn(`广告类型参数不存在：${value}`);
        }
        return isValid;
      }
    },
  },

  data() {
    return {
      //广告数据
      list: [],
      //广告类型
      typeData: {
        //首页
        homePage: 0,
        //事故车
        carInAccident: 1,
        //水淹车
        waterBubbleCar: 2,
        //二手车
        usedCar: 3,
        //配件
        accessories: 4,
        //求购
        toBuy: 5,
      },
      baseImgURL: undefined,
    }
  },
  created() {
    this.init()
  },
  methods: {
    /**
     * 初始化数据
     */
    init() {
      this.getList(this.type)
      this.baseImgURL = process.env.VUE_APP_BASE_IMG;
    },

    /**
     * 获取广告
     */
    getList(type) {
      // 查询事故车广告
      sendPostByKeyValue("/advertising/list", {type: type})
          .then((response) => {
            if (response['code'] === 200) {
              this.list = response['data']
            }
          })
          .catch((err) => {
            console.log('获取广告失败：', err);
          });
    },
    /**
     * 获取第一个图片的地址(临时使用)
     */
    getFirstImage() {
      let list = this.list
      if (!list || 0 === list.length)
        return ''
      else
        return list[(this.index % list.length)]
    },
  }

}
</script>

<style scoped>

</style>
