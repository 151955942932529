<template>
  <div class="">
    <el-upload
        :class="{ hide: hideUpload }"
        ref="PictureCardUpload"
        list-type="picture-card"
        :action="baseImgURL+'/web/file/fileUpload'"
        :headers="headers"
        :accept="accept"
        :limit="limit"
        :auto-upload="true"
        :on-exceed="handleExceed"
        :on-success="uploadSuccess"
        :on-error="uploadError"
        :before-upload="beforeAvatarUpload"
        :on-preview="handlePictureCardPreview"
        :file-list="localUrlList"
        :on-remove="handleRemove">
      <div class="btn_upload" slot="trigger">
        <i class="el-icon-plus mb_3"></i>
        <span>{{ upTitle }}</span>
      </div>
      <div slot="file" slot-scope="{file}">
        <!--图片回显-->
        <img style="width: 260px;height: 180px;display: block;" :src="(absolute ?  '' : baseImgURL)+file.url"
             :alt="showUrlToAlt?(absolute ?  '' : baseImgURL)+file.url:''">

        <!--页面按钮-->
        <span class="el-upload-list__item-actions">
          <!--放大-->
          <span class="el-upload-list__item-preview" @click="handlePictureCardPreview(file)">
            <i class="el-icon-zoom-in"></i>
          </span>
          <!--移除-->
          <span class="el-upload-list__item-delete" @click="handleRemove(file)">
            <i class="el-icon-delete"></i>
          </span>
        </span>
      </div>
    </el-upload>

    <!--大图预览-->
    <el-image-viewer :zIndex='9999' v-if="imgViewerVisible" :on-close="closeImgViewer" :url-list="imgViewerList"/>
  </div>


</template>

<script>
export default {
  /**
   * 组件名称
   */
  name: "ImagePictureCardUpload",
  /**
   * 传入参数
   */
  props: {

    // 值
    value: [String, Object, Array],
    /**
     * 显示日志
     */
    showLog: {
      type: Boolean, // 类型
      default: false // 默认值
    },
    /**
     * 显示url在alt上
     */
    showUrlToAlt: {
      type: Boolean, // 类型
      default: false // 默认值
    },
    /**
     * 绝对地址
     */
    absolute: {
      type: Boolean, // 类型
      default: false // 默认值
    },
    /**
     *  文件限制类型, 例如["png", "jpg", "jpeg"]
     */
    type: {
      type: Array,
      default: () => ['png', 'jpg', 'jpeg'],
    },
    /**
     * 大小限制(MB)
     */
    size: {
      type: Number,
      default: 5,
    },
    /**
     * 最大允许上传个数 2个
     */
    limit: {
      type: Number,
      default: 2,
    },
    /**
     * 上传标题
     */
    upTitle: {
      type: String,
      default: '上传图片',
    },
  },
  /**
   * 组件自身参数
   * @returns {{}}
   */
  data() {
    return {
      //组件内部使用url
      localUrlList: [],
      //图片服务器地址
      baseImgURL: undefined,
      //携带的token
      headers: {},
      //通过type的自定义筛选格式
      accept: undefined,
      //大图地址集合
      imgViewerList: [],
      //大图显示
      imgViewerVisible: false,

      hideUpload: false

    }
  },
  /**
   * 属性监听
   */
  watch: {
    /**
     * 监听传入值
     * @param newValue 新值
     * @param oldValue 旧值
     */
    value(newValue, oldValue) {
      this.log(`value值变化\n新值:${newValue ? JSON.stringify(newValue) : ''}\n旧值:${oldValue ? JSON.stringify(oldValue) : ''}`)
      try {
        this.localUrlList = newValue ? JSON.parse(newValue) : []
        if (!Array.isArray(this.localUrlList)) this.localUrlList = []
      } catch (e) {
        this.localUrlList = []
      }
      // 校验是否需要添加按钮
      try {
        this.hideUpload = this.localUrlList.length >= this.limit;
      } catch (e) {
        this.hideUpload = true;
      }
    }
  },
  components: {
    //注册大图展开
    'el-image-viewer': () => import('element-ui/packages/image/src/image-viewer')
  },
  /**
   * 页面初始化执行方法
   */
  mounted() {
    try {
      this.localUrlList = this.value ? JSON.parse(this.value) : []
      if (!Array.isArray(this.localUrlList)) this.localUrlList = []
    } catch (e) {
      this.localUrlList = []
    }
    this.baseImgURL = process.env.VUE_APP_BASE_IMG;
    this.headers = {
      Token: this.$store.state.user.token,
    };
    //通过文件类型，允许上传文件格式
    if (this.type && this.type.length) {
      let type = JSON.parse(JSON.stringify(this.type))
      this.accept = type.map(type => '.' + type).join(', ')
      this.log(`允许上传格式为:${this.accept}`)
    }
  },
  /**
   * 组件方法
   */
  methods: {
    /**
     * 图片放大
     * @param file 文件
     */
    handlePictureCardPreview(file) {
      // 数据清空
      this.imgViewerList = []
      if (this.localUrlList && this.localUrlList.length && file && file.url) {
        let head = []
        let trail = []
        // 循环添加数据
        this.localUrlList.forEach(img => {
          if (img.url === file.url || head.length) {
            head.push((this.absolute ? '' : this.baseImgURL) + img.url)
          } else {
            trail.push((this.absolute ? '' : this.baseImgURL) + img.url)
          }
        })
        // 数据拼接
        this.imgViewerList = this.imgViewerList.concat(head)
        this.imgViewerList = this.imgViewerList.concat(trail)

        this.log(`大图展示数据集合为:\n${this.imgViewerList.join("\n")}`)
        this.showImgViewer()
      }

    },

    /**
     * 显示大图
     */
    showImgViewer() {
      this.imgViewerVisible = true;
      const m = (e) => {
        e.preventDefault()
      };
      document.body.style.overflow = 'hidden';
      document.addEventListener("touchmove", m, false); // 禁止页面滑动

    },
    /**
     * 关闭大图
     */
    closeImgViewer() {
      this.imgViewerVisible = false;
      const m = (e) => {
        e.preventDefault()
      };
      document.body.style.overflow = 'auto';
      document.removeEventListener("touchmove", m, true);
    },
    /**
     * 组件日志打印
     * @param value 输出值
     * @param color 颜色，默认为绿色
     */
    log(value, color = 'yellow') {
      if (this.showLog)
        console.log(`%cImageSimpleUpload-log:\n${value}`, `color: ${color};`)
    },
    /**
     * 图片移除
     * @param file 文件
     * @param fileList 文件集合
     */
    handleRemove(file, fileList) {
      for (let num = 0; num < this.localUrlList.length; num++) {
        if (this.localUrlList[num].url === file.url) {
          this.localUrlList.splice(num, 1)
          break;
        }
      }
      // 通知父组件修改值
      this.emitUpdateValue()
    },


    /**
     * 上传成功
     * @param response 响应
     * @param file 文件
     * @param fileList 文件集合
     */
    uploadSuccess(response, file, fileList) {
      if (response) {
        let data = response.data
        let img = {
          uid: file.uid,
          name: data.fileName,
          url: (this.absolute ? this.baseImgURL : '') + data.fileUrl,
        }
        this.localUrlList.push(img)

        this.log(`上传成功:${img ? JSON.stringify(img) : ''}`)
        this.removeUploadSuccess(file)
        // 通知父组件修改值
        this.emitUpdateValue()
      }
    },
    /**
     * 更新父类值
     */
    emitUpdateValue() {
      let data = ""
      if (this.localUrlList && this.localUrlList.length)
        data = this.localUrlList
      this.$emit('input', data ? JSON.stringify(data) : undefined);
    },
    /**
     * 移除上传成功的图片
     */
    removeUploadSuccess(file) {
      let name = file.name
      for (let num = 0; num < this.$refs.PictureCardUpload.uploadFiles.length; num++) {
        if (this.$refs.PictureCardUpload.uploadFiles[num].name === name && !this.$refs.PictureCardUpload.uploadFiles[num].url) {
          this.$refs.PictureCardUpload.uploadFiles.splice(num, 1)
          break;
        }
      }
    },
    /**
     * 上传失败
     * @param err 错误
     * @param file 文件
     * @param fileList 文件集合
     */
    uploadError(err, file, fileList) {
      this.log(`上传失败:${err.message()}`)
      this.$message.error('上传失败')
    },
    /**
     * 上传异常
     * @param files 文件
     * @param fileList 文件集合
     */
    handleExceed(files, fileList) {
      this.$message.warning(`当前限制选择 ${this.limit} 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length} 个文件`);
    },

    /**
     * 文件大小限制
     * @param file 文件大小限制
     * @returns {boolean}
     */
    beforeAvatarUpload(file) {
      // 文件大小检测
      if (this.size && file) {
        const isLt = file.size / 1024 / 1024 < this.size;
        if (!isLt) {
          this.$message.error(`上传文件大小不能超过 ${this.size} MB!`);
          return false;
        }
      }


      // 校检文件类型
      if (this.type && file) {
        // 文件类型
        let fileExtension = "";

        // 获取文件类型
        if (file.name.lastIndexOf(".") > -1) {
          fileExtension = file.name.slice(file.name.lastIndexOf(".") + 1);
        }
        // 判断类型状态
        const isTypeOk = this.type.some((type) => {
          if (file.type.indexOf(type) > -1) return true;
          return (fileExtension && fileExtension.indexOf(type) > -1);
        });
        if (!isTypeOk && file) {
          this.$message.error(`文件格式不正确, 请上传${this.type.join("/")}格式文件!`);
          return false;
        }
      }

      return true
    },
  }
}
</script>

<style scoped>
::v-deep .hide .el-upload--picture-card {
  display: none;
}
</style>
