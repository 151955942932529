<template>
  <div id="app">
    <!-- <homePage msg=""/> -->
    <router-view></router-view>
  </div>
</template>

<script>
// import homePage from './components/DetailSwiper.vue'

export default {
  name: 'App',
  components: {
    // homePage
  }
}
</script>


<style>
.dialog_input{
  width: 280px !important
}
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: inherit;
}
</style>
