/**
 * 获取支付跳转回来的地址，并可选择编码
 * @param pathname 路由
 * @returns {String} 包含额外方法的特殊字符串对象
 */
export function getReturnUrl(pathname) {
    let origin = location.origin;
    let fullUrl = origin + pathname;

    // 创建一个新的String对象，附加方法encodeUrl
    let urlObject = new String(fullUrl);
    urlObject.encodeUrl = function () {
        return encodeURIComponent(this.toString());
    };

    return urlObject;
}

/**
 获取图片集合
 @param data 数据容器
 @param types 获取属性类型
 */
export function getPhotoList(data, types) {
    let photoList = []
    if (null == data)
        return photoList
    types.forEach(type => {
        let urls = data[type]
        if (urls) {
            try {
                let urlList = JSON.parse(urls)
                if (Array.isArray(urlList))
                    photoList = photoList.concat(urlList)
            } catch (e) {
                console.log("图片对象转换异常：", e)
            }
        }
    })
    return photoList
}

export default {
    getReturnUrl,
    getPhotoList,
};
