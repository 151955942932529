import Vue from 'vue'

// 图片简单上传组件 全局使用
import ImageSimpleUpload from '@/components/Upload/ImageSimpleUpload'
Vue.component("ImageSimpleUpload",ImageSimpleUpload);

// 图片墙上传组件 全局使用
import ImagePictureCardUpload from '@/components/Upload/ImagePictureCardUpload'
Vue.component("ImagePictureCardUpload",ImagePictureCardUpload);

// 轮播图显示组件 全局使用
import SlideshowImage from '@/components/ShowImage/SlideshowImage'
Vue.component("SlideshowImage",SlideshowImage);

// 广告显示组件 全局使用
import AdvertisingImage from '@/components/ShowImage/AdvertisingImage'
Vue.component("AdvertisingImage",AdvertisingImage);

// swiper组件 全局使用
import DetailSwiper from '@/components/Swiper/DetailSwiper'
Vue.component("DetailSwiper",DetailSwiper);

// 集成文本编辑组件 全局使用
// import Tinymce from '@/components/Tinymce'
// Vue.component("Tinymce",Tinymce);
