import Vue from 'vue'
import App from './App.vue'
import store from './store'
import '@/components' // components
import VueRouter from 'vue-router'
import router from './router'
// elementUI
import ElementUI from 'element-ui'
import 'swiper/css/swiper.css'
import moment from 'moment-timezone'
import '@icon-park/vue/styles/index.css'
import 'remixicon/fonts/remixicon.css'
import '@/assets/sass/theme.scss'

import serviceTel from '@/utils/serviceTel'
import urlUtil from '@/utils/urlUtil'
//赋值到vue里面
Vue.prototype.$serviceTel = serviceTel
Vue.prototype.$urlUtil = urlUtil


Vue.use(VueRouter)

import AdRotation from './components/ShowImage/advertising.vue';
Vue.component('ad-rotation', AdRotation);

Vue.filter('toFixed', function (value, digits = 2) {
    if (!value) return '0.00';
    return Number(value).toFixed(digits);
  });

/**
 * 获取会员类型
 * @returns {null|*} 会员类型
 */
function getUserRole() {
    // 示例：从localStorage获取用户角色
    try {
        let userStr = localStorage.getItem('vue_user_info')
        if (!userStr)
            return null
        let user = JSON.parse(userStr);
        if (!user)
            return null
        return user.memberType
    } catch (ex) {
        return null
    }
}

// 使用beforeEach全局守卫来检查权限
router.beforeEach((to, from, next) => {
    // 检查匹配的路由中是否有设置roles
    const hasRoles = to.matched.some(record => record.meta && record.meta.roles);

    if (hasRoles) {
        const userRole = getUserRole(); // 获取当前用户角色
        // 检查用户是否有任何匹配路由所需的角色
        const hasPermission = to.matched.some(record =>
            //会员类型 0-其他 1-拖车司机 2-拖车调度人 3-配件商家 4-买卖商家 5-残值商（公司） 6-残值商（个人）
            record.meta && record.meta.roles && record.meta.roles.includes(userRole)
        );

        if (!userRole && userRole !== 0) {
            // 用户未登录或无角色信息，重定向到登录页面
            next({path: '/login'});
        } else if (hasPermission) {
            // 用户具有所需角色，允许访问
            next();
        } else {
            // 用户没有权限访问当前路由
            alert('您没有权限访问该页面。');
            next({path: '/'}); // 或者重定向到一个“无权限”页面
        }
    } else {
        // 没有角色要求的路由，直接通过
        next();
    }
})


/**
 * 格式化为万元单位
 * 转为数字，除以10000，保留两位小数
 */
Vue.filter('wanYuan', function (number) {
    if (number) {
        try {
            return (Number(number) / 10000).toFixed(2)
        }catch (e){
            console.log("万元单位格式化异常，格式化值为：",number)
            return "金额异常"
        }
    }

    return "";
})

//时间格式化

Vue.filter('dateYMDHMSFormat', function (dateStr, pattern = 'YYYY-MM-DD HH:mm:ss') {
    if (dateStr) {
        return moment(dateStr).format(pattern);
    } else {
        return "";
    }

})


Vue.filter('dateYMDFormat', function (dateStr, pattern = 'YYYY-MM-DD') {
    if (dateStr) {
        return moment(dateStr).format(pattern);
    } else {
        return "";
    }
})


Vue.filter('vipDateYMDFormat', function (dateStr, pattern = 'YYYY/MM/DD') {
    if (dateStr) {
        return moment(dateStr).format(pattern);
    } else {
        return "";
    }
})

Vue.use(ElementUI)

Vue.config.productionTip = false
new Vue({
    router,
    store,
    render: h => h(App),
}).$mount('#app')


/**
 * StorageEvent 监听事件
 */
Vue.prototype.resetSetItem = function (key, newVal) {
    if (key === 'photo' || key === 'vue_user_info' || key==='nickname' || key==='activeIndex') {
        // 创建一个StorageEvent事件
        var newStorageEvent = document.createEvent('StorageEvent');
        const storage = {
            setItem: function (k, val) {
                localStorage.setItem(k, val);
                // 初始化创建的事件
                newStorageEvent.initStorageEvent('setItem', false, false, k, null, val, null, null);

                // 派发对象
                window.dispatchEvent(newStorageEvent)
            }
        }
        return storage.setItem(key, newVal);
    }
}
