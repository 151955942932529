<template>
  <div>
    <el-upload class="avatar-uploader" :action="baseImgURL+'/web/file/fileUpload'" :show-file-list="false"
               :headers="headers" name="file" :on-success="uploadSuccess" :on-error="uploadError"
               :accept="accept" :before-upload="beforeAvatarUpload">
      <!--    <img v-if="localUrl" :src="(this.absolute ?  '' : this.baseImgURL) + localUrl" :alt="showUrlToAlt?localUrl:''"-->
      <!--         style="width: 260px;height: 180px;display: block;"-->
      <!--    />-->
      <div class="image-wrapper">
        <img
            v-if="localUrl"
            :src="(this.absolute ? '' : this.baseImgURL) + localUrl"
            :alt="showUrlToAlt ? localUrl : ''"
            style="width: 260px; height: 180px; display: block;"
        />
        <div class="buttons">
          <span @click.stop="handlePictureCardPreview(localUrl)"><i class="el-icon-zoom-in"></i></span>
          <span @click.stop="handleRemove(localUrl)"><i class="el-icon-delete"></i></span>
        </div>
      </div>
      <div v-if="!localUrl" class="avatar-uploader-trigger">
        <i class="el-icon-plus avatar-uploader-icon"></i>
        <span class="avatar-uploader-text">{{ upTitle }}</span>
      </div>
    </el-upload>
    <!--大图预览-->
    <el-image-viewer :zIndex='9999' v-if="imgViewerVisible" :on-close="closeImgViewer" :url-list="imgViewerList"/>
  </div>
</template>

<script>
export default {
  /**
   * 组件名称
   */
  name: "ImageSimpleUpload",
  /**
   * 传入参数
   */
  props: {

    // 值
    value: [String, Object, Array],
    /**
     * 显示日志
     */
    showLog: {
      type: Boolean, // 类型
      default: false // 默认值
    },
    /**
     * 显示url在alt上
     */
    showUrlToAlt: {
      type: Boolean, // 类型
      default: false // 默认值
    },
    /**
     * 绝对地址
     */
    absolute: {
      type: Boolean, // 类型
      default: false // 默认值
    },
    /**
     *  文件限制类型, 例如["png", "jpg", "jpeg"]
     */
    type: {
      type: Array,
      default: () => ['png', 'jpg', 'jpeg'],
    },
    /**
     * 大小限制(MB)
     */
    size: {
      type: Number,
      default: 5,
    },
    /**
     * 上传标题
     */
    upTitle: {
      type: String,
      default: '上传图片',
    },
  },
  /**
   * 组件自身参数
   * @returns {{}}
   */
  data() {
    return {
      imgViewerList: [],
      //大图显示
      imgViewerVisible: false,
      //组件内部使用url
      localUrl: undefined,
      //图片服务器地址
      baseImgURL: undefined,
      //携带的token
      headers: {},
      //通过type的自定义筛选格式
      accept: undefined,
    }
  },
  /**
   * 属性监听
   */
  watch: {
    /**
     * 监听传入值
     * @param newValue 新值
     * @param oldValue 旧值
     */
    value(newValue, oldValue) {
      this.log(`value值变化\n新值:${newValue}\n旧值:${oldValue}`)
      this.localUrl = newValue ? JSON.parse(JSON.stringify(newValue)) : undefined
    }
  },
  components: {
    //注册大图展开
    'el-image-viewer': () => import('element-ui/packages/image/src/image-viewer')
  },
  /**
   * 页面初始化执行方法
   */
  mounted() {
    this.localUrl = this.value ? JSON.parse(JSON.stringify(this.value)) : undefined
    this.baseImgURL = process.env.VUE_APP_BASE_IMG;
    this.headers = {
      Token: this.$store.state.user.token,
    };
    //通过文件类型，允许上传文件格式
    if (this.type && this.type.length) {
      let type = JSON.parse(JSON.stringify(this.type))
      this.accept = type.map(type => '.' + type).join(', ')
      this.log(`允许上传格式为:${this.accept}`)
    }
  },
  /**
   * 组件方法
   */
  methods: {
    /**
     * 显示大图
     */
    showImgViewer() {
      this.imgViewerVisible = true;
      const m = (e) => {
        e.preventDefault()
      };
      document.body.style.overflow = 'hidden';
      document.addEventListener("touchmove", m, false); // 禁止页面滑动

    },
    /**
     * 关闭大图
     */
    closeImgViewer() {
      this.imgViewerVisible = false;
      const m = (e) => {
        e.preventDefault()
      };
      document.body.style.overflow = 'auto';
      document.removeEventListener("touchmove", m, true);
    },
    /**
     * 图片放大
     * @param file 文件
     */
    handlePictureCardPreview(url) {
      if (!url)
        return this.$message.error('图片路径为空')
      // 数据清空
      this.imgViewerList = []
      if (url && url != '')
        this.imgViewerList.push(this.baseImgURL + url)
      this.showImgViewer()
    },
    /**
     * 图片移除
     * @param file 文件
     * @param fileList 文件集合
     */
    handleRemove(url) {
      if (!url)
        return this.$message.error('图片路径为空')
      if (url && url != '')
        this.localUrl = undefined;
      this.returnImage()
    },
    /**
     * 组件日志打印
     * @param value 输出值
     * @param color 颜色，默认为绿色
     */
    log(value, color = 'yellow') {
      if (this.showLog)
        console.log(`%cImageSimpleUpload-log:\n${value}`, `color: ${color};`)
    },
    /**
     * 上传成功
     * @param response 响应
     * @param file 文件
     * @param fileList 文件集合
     */
    uploadSuccess(response, file, fileList) {
      if (response) {
        //上传成功填充
        this.localUrl = (this.absolute ? this.baseImgURL : '') + response.data.fileUrl;
        // 通知父组件修改值
        this.$emit('input', this.localUrl);
        this.log(`上传成功:${this.localUrl}`)
      }
    },
    /**
     * 上传失败
     * @param err 错误
     * @param file 文件
     * @param fileList 文件集合
     */
    uploadError(err, file, fileList) {
      this.log(`上传失败:${err.message()}`)
      this.$message.error('上传失败')
    },

    /**
     * 文件大小限制
     * @param file 文件大小限制
     * @returns {boolean}
     */
    beforeAvatarUpload(file) {
      // 文件大小检测
      if (this.size && file) {
        const isLt = file.size / 1024 / 1024 < this.size;
        if (!isLt) {
          this.$message.error(`上传文件大小不能超过 ${this.size} MB!`);
          return false;
        }
      }


      // 校检文件类型
      if (this.type && file) {
        // 文件类型
        let fileExtension = "";

        // 获取文件类型
        if (file.name.lastIndexOf(".") > -1) {
          fileExtension = file.name.slice(file.name.lastIndexOf(".") + 1);
        }
        // 判断类型状态
        const isTypeOk = this.type.some((type) => {
          if (file.type.indexOf(type) > -1) return true;
          return (fileExtension && fileExtension.indexOf(type) > -1);
        });
        if (!isTypeOk && file) {
          this.$message.error(`文件格式不正确, 请上传${this.type.join("/")}格式文件!`);
          return false;
        }
      }

      return true
    },
  }
}
</script>
<!--<style lang="scss" scoped>-->
<!--.editor-slide-upload {-->
<!--  margin-bottom: 20px;-->
<!--  ::v-deep .el-upload&#45;&#45;picture-card {-->
<!--    width: 100%;-->
<!--  }-->
<!--}-->
<!--</style>-->

<style scoped>
.avatar-uploader-trigger {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 180px;
  width: 260px;
  border: 1px solid #d9d9d9;
  cursor: pointer;
  text-align: center;
  transition: border-color 0.3s;
}

.avatar-uploader-trigger:hover {
  border-color: #F4701F; /* 鼠标悬浮时边框颜色 */
}

.avatar-uploader-icon {
  font-size: 24px;
  color: #999; /* 默认图标颜色 */
  margin-bottom: 8px;
  transition: color 0.3s; /* 添加过渡效果给图标颜色 */
}

.avatar-uploader-trigger:hover .avatar-uploader-icon {
  color: #F4701F; /* 鼠标悬浮时图标颜色 */
}

.avatar-uploader-text {
  font-size: 14px;
  color: #666; /* 默认文本颜色 */
  transition: color 0.3s; /* 添加过渡效果给文本颜色 */
}

.avatar-uploader-trigger:hover .avatar-uploader-text {
  color: #F4701F; /* 鼠标悬浮时文本颜色 */
}

.image-wrapper {
  position: relative; /* 确保按钮能相对于图片定位 */
  //display: inline-block; /* 确保宽度和高度能正确应用 */
}

.buttons {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  cursor: default;
  text-align: center;
  color: #fff;
  opacity: 0;
  font-size: 20px;
  background-color: rgba(0, 0, 0, .5);
  transition: opacity .3s;

  &::after {
    display: inline-block;
    content: "";
    height: 100%;
    vertical-align: middle
  }

  span {
    display: none;
    cursor: pointer;
  }

  span + span {
    margin-left: 15px;
  }

  .el-upload-list__item-delete {
    position: static;
    font-size: inherit;
    color: inherit;
  }

  &:hover {
    opacity: 1;

    span {
      display: inline-block;
    }
  }
}

.image-wrapper:hover .buttons {
  opacity: 1; /* 鼠标悬浮时显示按钮 */
}
</style>
