import {getInfo, login} from '@/api/user'
import {getToken, getUserInfo, removeToken, removeUserInfo, setToken, setUserInfo} from '@/utils/auth'

const getDefaultState = () => {
    return {
        token: getToken(),
        name: '',
        avatar: '',
        userInfo: getUserInfo(),
        permissionList: ''
    }
}

const state = getDefaultState()

const mutations = {
    RESET_STATE: (state) => {
        Object.assign(state, getDefaultState())
    },
    SET_TOKEN: (state, token) => {
        state.token = token
    },
    SET_NAME: (state, name) => {
        state.name = name
    },
    SET_AVATAR: (state, avatar) => {
        state.avatar = avatar
    },
    SET_USERINFO: (state, userInfo) => {
        state.userInfo = userInfo
    },
    SET_PERMISSIONLIST: (state, permissionList) => {
        state.permissionList = permissionList
    }
}

const actions = {
    // user login
    login({commit}, userInfo) {
        const {username, password, mobile, verificationCode,type} = userInfo
        let data = {
            // username: username.trim(),
            username: username,
            password: password,
            mobile: mobile,
            verificationCode: verificationCode,
            type: type,
        }
        return new Promise((resolve, reject) => {
            login(data).then(response => {
                const {data} = response
                commit('SET_TOKEN', data.token)
                commit('SET_NAME', data['nickname'])
                commit('SET_AVATAR', data['photo'])
                commit('SET_USERINFO', data)
                console.log('data', data)
                setToken(data.token)
                setUserInfo(data);
                resolve()
            }).catch(error => {
                reject(error)
            })
        })
    },


    // get user info
    getInfo({commit}) {
        return new Promise((resolve, reject) => {
            getInfo().then(response => {
                if (response['code'] !== 200) {
                    localStorage.clear()
                }
                const {data} = response
                commit('SET_USERINFO', data)
                resolve(data)
            }).catch(error => {
                localStorage.clear()
                reject(error)
            })
        }).catch(error => {
            console.log('error', error)
            localStorage.clear()
            // reject(error)
        })
    },

    // user logout
    logout({commit}) {
        return new Promise((resolve) => {
            //清除前端登录凭证
            commit('SET_TOKEN', '')
            commit('SET_NAME', '')
            commit('SET_AVATAR', '')
            commit('SET_USERINFO', '')
            commit('SET_PERMISSIONLIST', '')
            removeToken()
            removeUserInfo()
            resolve()
        })
    },

    // remove token
    resetToken({commit}) {
        return new Promise(resolve => {
            commit('SET_TOKEN', '')
            removeToken()
            resolve()
        })
    }
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}

